/* Set all fonts to Pretendard */
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/Pretendard-1.3.9/web/variable/woff2/PretendardVariable.woff2') format('woff2-variations');
}

body {
  margin: 0;
  font-family: 'Pretendard Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Rest of your existing styles */
@tailwind base;
@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5 {
    @apply text-base;
  }
  ul {
    @apply list-inside list-disc;
  }
  a {
    @apply text-sky-500 hover:text-sky-600;
  }
}
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
