.App-link {
  color: #61dafb;
}

@font-face {
	font-family: 'Pretendard Variable';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: url('./fonts/Pretendard-1.3.9/web/variable/woff2/PretendardVariable.woff2') format('woff2-variations');
}
